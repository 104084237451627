import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import { DiscussionEmbed } from 'disqus-react'

import Layout from '../components/layout'
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!){
    profile_pic: file(relativePath: {
        eq: "profile_pic.jpeg"
      }) {
      childImageSharp {
        fluid(grayscale: true) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ghostPost(slug: {eq: $slug}) {
      id
      url
      slug
      title
      feature_image
      html
      published_at(formatString: "MMMM DD, YYYY")
      authors {
        profile_image
        name
      }
    }
  }
`

const Blog = props => {

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: {
      url: props.data.ghostPost.url,
      identifier: props.data.ghostPost.slug,
      title: props.data.ghostPost.title
    }
  }
  return (
    <Layout>
      <SEO title={props.data.ghostPost.title} />
      <div className="post">
        <div className="post__title">
          <h1>{props.data.ghostPost.title}</h1>
        </div>
        <div className="post__featured-image">
          <img src={props.data.ghostPost.feature_image} />
        </div>
        <div className="post__author">
          <Img
            fluid={ props.data.profile_pic.childImageSharp.fluid }
            className="post__author--profile-pic"
            loading="lazy"
          />
          <div className="post__author--content">
            <div className="author-name">
              <h3>Arthur Elbakyan</h3>
            </div>
            <div className="meta">
              <p>{props.data.ghostPost.published_at}</p>
            </div>
          </div>
        </div>
        <div className="post__content" dangerouslySetInnerHTML={{__html: props.data.ghostPost.html}}></div>
        {/* Enter the comments code here! */}
        <div className="post__comments">
          <DiscussionEmbed {...disqusConfig} />
        </div>
      </div>
    </Layout>
  )
}

export default Blog